import Api from 'api/Api';
import { ITasksFilter, ITasksListGetResponse } from 'modules/tasks/models/types';

import {
  IRouteCalculateForm,
  IRouteCalculateResponse,
  IRouteCreateForm,
  IRouteCreateResponse,
  IRouteCreateFormYandex,
  IRoutesCreateResponse,
} from '../models/types';

export default class RouteGeneratorApi {
  static calculateRoute(data: IRouteCalculateForm): Promise<IRouteCalculateResponse> {
    return Api.post(data, '/api/v1/routes/calculate');
  }

  static createRoute(data: IRouteCreateForm): Promise<IRouteCreateResponse> {
    return Api.post(data, '/api/v1/routes');
  }

  static createRoutes(data: IRouteCreateForm[]): Promise<IRoutesCreateResponse> {
    return Api.post(data, '/api/v1/routes/list');
  }

  static createRouteByYandex(data: IRouteCreateFormYandex): Promise<IRouteCreateResponse> {
    return Api.post(data, '/api/v1/routes/build/yandex');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static log(msg: string): Promise<any> {
    return Api.post({ msg }, '/api/v1/log');
  }

  static getTasksList(filter: ITasksFilter, signal?: AbortSignal): Promise<ITasksListGetResponse> {
    const dateList =
      filter.deliveryDateList && filter.deliveryDateList.length > 0
        ? filter.deliveryDateList
        : filter.deliveryDate && [filter.deliveryDate];
    const query = {
      page: filter.current,
      page_size: filter.pageSize,
      q: filter.search,
      delivery_date_list: dateList,
      shop_guid_list: filter.shop,
      warehouse_guid_list: filter.warehouses,
      time_slot_start: filter.from,
      time_slot_end: filter.to,
      type_list: filter.type,
      status_list: filter.status,
      delivery_method_guid_list: filter.deliveryMethod,
      is_problem: filter.isProblem,
    };
    const requestId = Date.now().toString();
    return Api.get('/api/v1/tasks/generator', query, signal, {
      'X-Delivery-Tasks-Group': requestId,
    });
  }
}
