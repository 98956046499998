import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IPaymentMethodCreateResponse,
  IPaymentMethodEditResponse,
  IPaymentMethodForm,
  IPaymentMethodGetResponse,
  IPaymentMethodListGetResponse,
} from 'modules/payment-method/models/types';

export default class PaymentMethodApi {
  static getPaymentMethod(guid: string): Promise<IPaymentMethodGetResponse> {
    return Api.get(`/api/v1/payment-method/${guid}`);
  }

  static getPaymentMethodList(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IPaymentMethodListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/payment-method', query, signal);
  }

  static createPaymentMethod(data: IPaymentMethodForm): Promise<IPaymentMethodCreateResponse> {
    return Api.post(data, '/api/v1/payment-method');
  }

  static editPaymentMethod(
    guid: string,
    data: IPaymentMethodForm,
  ): Promise<IPaymentMethodEditResponse> {
    return Api.put(data, `/api/v1/payment-method/${guid}`);
  }
}
