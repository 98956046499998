import React from 'react';

import { Dropdown } from 'antd';

import { FilterIcon } from 'modules/common/components/Icons';
import { translate } from 'modules/localization';

interface IProps {
  sortType: string;
  setSortType: (key: string) => void;
}

const SortButton = ({ sortType, setSortType }: IProps) => {
  const menu = [
    {
      label: translate('defaultSort'),
      key: 'defaultSort',
    },
    {
      label: translate('ascendingAmountSort'),
      key: 'ascendingAmountSort',
    },
    {
      label: translate('descendingAmountSort'),
      key: 'descendingAmountSort',
    },
    {
      label: translate('ascendingWeightSort'),
      key: 'ascendingWeightSort',
    },
    {
      label: translate('descendingWeightSort'),
      key: 'descendingWeightSort',
    },
    {
      label: translate('ascendingVolumeSort'),
      key: 'ascendingVolumeSort',
    },
    {
      label: translate('descendingVolumeSort'),
      key: 'descendingVolumeSort',
    },
  ];

  const handleMenuClick = ({ key }): void => setSortType(String(key));

  return (
    <Dropdown
      menu={{
        items: menu,
        onClick: handleMenuClick,
        selectedKeys: [sortType],
      }}
      trigger={['click']}
    >
      <div>
        <FilterIcon className="rg-routecontent__sort" onClick={(e) => e.preventDefault()} />
      </div>
    </Dropdown>
  );
};

export default SortButton;
