import './style.less';

import React from 'react';

import ButtonDelete from 'modules/directory/components/ActionButtons/ButtonDelete';
import ButtonEdit from 'modules/directory/components/ActionButtons/ButtonEdit';

interface IMessage {
  deleteTitle: string;
  deleteCancel: string;
}

interface IPermissions {
  edit: boolean;
  delete?: boolean;
}

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  baseUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionDelete?: any;
  message?: IMessage;
  permissions: IPermissions;
}

export default ({ item, baseUrl, actionDelete, message, permissions }: IProps) => {
  return (
    <div className="action-buttons">
      <ButtonEdit isPermitted={permissions.edit} item={item} baseUrl={baseUrl} />
      <ButtonDelete
        isPermitted={permissions.delete}
        item={item}
        actionDelete={actionDelete}
        message={message}
      />
    </div>
  );
};
