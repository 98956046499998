import React from 'react';

import { ColumnsType } from 'antd/es/table';

import { IPermission } from 'modules/core/models/types';
import Actions from 'modules/directory/components/ActionButtons';
import { translate } from 'modules/localization';
import { IPaymentMethod } from 'modules/payment-method/models/types';

export const getColumns = (
  baseUrl: string,
  permissions: IPermission,
): ColumnsType<IPaymentMethod> => {
  return [
    {
      dataIndex: 'code',
      title: translate('paymentMethodCode'),
      key: 'code',
      width: '20%',
    },
    {
      dataIndex: 'web_name',
      title: translate('paymentMethodWebName'),
      key: 'web_name',
      width: '20%',
    },
    {
      dataIndex: 'mob_name',
      title: translate('paymentMethodMobName'),
      key: 'mob_name',
      width: '20%',
    },
    {
      dataIndex: 'no_payment_permission',
      title: translate('paymentMethodNoPermissions'),
      key: 'no_payment_permission',
      width: '20%',
      render: (no_payment_permission: boolean): React.ReactNode => (
        <span>{no_payment_permission ? translate('yes') : translate('no')}</span>
      ),
    },
    {
      dataIndex: 'guid',
      key: 'action',
      render: (_, item): React.ReactNode => (
        <Actions
          item={item}
          baseUrl={baseUrl}
          permissions={{
            edit: permissions['payment-method.update'],
          }}
        />
      ),
      width: '10%',
    },
  ];
};
