import './style.less';

import React, { useEffect, useRef } from 'react';

import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import { IPagination } from 'api/types';
import { getUrlPagination } from 'helpers/url';
import { getRowHandler } from 'modules/directory/helpers';
import { IProfile } from 'modules/user/models/types';
import { useStore } from 'services/store';

import { getColumns } from './helpers';

interface IProps {
  baseUrl: string;
}

const UserList = ({ baseUrl }: IProps) => {
  const { core, user } = useStore();

  const location = useLocation<Record<string, never>>();

  const updateList = (newPagination: IPagination): void => {
    const url = new URL(window.location.href);
    const query = url.searchParams.get('q');

    query ? user.search(query, newPagination) : user.getList(newPagination);
  };

  useEffect(() => {
    user.getRoles();
    updateList(getUrlPagination(user.pagination));
  }, []);

  const columns = getColumns(baseUrl, user.roles, user.delete);

  const listRef = useRef(null);
  const scrollIntoView = () => listRef.current.nativeElement.scrollIntoView({ behavior: 'smooth' });

  const getRowClassName = (record: IProfile) => {
    if (record.disabled) {
      return 'user-list-row__disabled';
    }
  };

  return (
    <Table<IProfile>
      ref={listRef}
      className="dir__list"
      dataSource={user.getUsersSortByActive}
      columns={columns}
      bordered={false}
      pagination={{
        ...user.pagination,
        simple: core.isMobile,
        showSizeChanger: true,
        onChange: scrollIntoView,
      }}
      rowKey="guid"
      onChange={updateList}
      onRow={getRowHandler(baseUrl, location)}
      loading={user.isLoadingList}
      rowClassName={getRowClassName}
    />
  );
};

export default observer(UserList);
