import React, { useState } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';

import classSet from 'helpers/classSet';
import { dateFormat, timeFormat } from 'helpers/string';
import ClusterSettingInfo from 'modules/clusters/components/ClusterSetting/ClusterSettingInfo';
import ExpandIcon from 'modules/clusters/components/ClusterSetting/ExpandIcon';
import { IClusterDeliveryMethod } from 'modules/clusters/models/types';
import DeliveryMethodName from 'modules/delivery-methods/components/DeliveryMethodName';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import EditButtons from './ClusterSettingEditButtons';

interface IProps {
  baseUrl: string;
  data: IClusterDeliveryMethod;
  clusterGuid: string;
}

const style = (isActive: boolean) => {
  return {
    transform: isActive ? 'rotate(180deg)' : '',
    transition: 'transform 150ms ease', // smooth transition
  };
};

const ClusterSettingDeliveryMethod = ({ baseUrl, data, clusterGuid }: IProps) => {
  const { core } = useStore();
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className={classSet({
        'cluster-setting-delivery-methods': true,
        'cluster-setting-delivery-methods__active': isActive,
      })}
    >
      <div className="cluster-setting-delivery-methods_header">
        <div className="cluster-setting-delivery-methods_header-content">
          {data.disabled ? (
            <div className="cluster-setting-circle cluster-setting-circle__disabled" />
          ) : (
            <div className="cluster-setting-circle cluster-setting-circle__active" />
          )}

          <div className="cluster-setting-delivery-methods_header-title">
            <DeliveryMethodName guid={data.delivery_method_guid} />
          </div>
          {isActive && core.permissions['clusters.update'] ? (
            <EditButtons
              baseUrl={baseUrl}
              clusterGuid={clusterGuid}
              deliveryMethodGuid={data.delivery_method_guid}
            />
          ) : null}
        </div>
        <div
          className="cluster-setting-delivery-methods_expander"
          onClick={() => setIsActive(!isActive)}
        >
          <ExpandIcon style={style(isActive)} />
        </div>
      </div>
      {isActive ? (
        <div className="cluster-setting-delivery-methods_body">
          <div className="cluster-setting-delivery-methods_content">
            <ClusterSettingInfo
              title={`${translate('deliveryMethod')}:`}
              content={<DeliveryMethodName guid={data.delivery_method_guid} />}
            />
            <ClusterSettingInfo
              title={`${translate('startDateAndTime')}:`}
              content={`${dayjs(data.handle_start).format(dateFormat.string)} ${dayjs(
                data.handle_start,
              ).format(timeFormat.simple)}`}
            />
            <ClusterSettingInfo
              title={`${translate('averageSpeed')},\n${translate('averageSpeedUnitKmPerHour')}:`}
              content={data.courier_avg_speed.toString()}
            />
          </div>
          <h2 className="cluster-setting-delivery-methods_h2">
            {translate('unloadingTimeCalculation')}
          </h2>
          <div className="cluster-setting-delivery-methods_content">
            <ClusterSettingInfo
              title={`${translate('unloadingTime')},\n${translate('timeUnitMinuteShort')}:`}
              content={data.order_unloading_delay}
            />
            <ClusterSettingInfo
              title={`${translate('orderWeightForExtraDelay')},\n${translate('weightUnit')}:`}
              content={data.order_weight_for_extra_delay}
            />
            <ClusterSettingInfo
              title={`${translate('orderUnloadingExtraDelay')},\n${translate(
                'timeUnitMinuteShort',
              )}:`}
              content={data.order_unloading_extra_delay}
            />
          </div>
          <h2 className="cluster-setting-delivery-methods_h2">{translate('routingStartPlan')}</h2>
          <div className="cluster-setting-delivery-methods_content">
            <ClusterSettingInfo
              title={translate('orderActualStrategy')}
              content={translate(data.order_actual_strategy)}
              extra={
                <Tooltip title={translate('orderActualStrategyMeans')}>
                  <QuestionCircleOutlined className="cluster-form-tooltip-icon" />
                </Tooltip>
              }
            />
            <ClusterSettingInfo
              title={`${translate('shearTime')}, ${translate('timeUnitMinuteShort')}:`}
              content={data.order_actual_delay}
              extra={
                <Tooltip title={translate('shearTimeMeans')}>
                  <QuestionCircleOutlined className="cluster-form-tooltip-icon" />
                </Tooltip>
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ClusterSettingDeliveryMethod;
