import React from 'react';

import { observer } from 'mobx-react-lite';

import Title from 'modules/common/components/Title';
import ButtonCreate from 'modules/directory/components/ActionButtons/ButtonCreate';
import ButtonCreateMobile from 'modules/directory/components/ActionButtons/ButtonCreateMobile';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  baseUrl: string;
}

const PaymentMethodHeader = ({ baseUrl }: IProps) => {
  const { core } = useStore();

  return (
    <div className="dir__header">
      <Title className="dir__title" size={Title.SIZE.H3} weight={Title.WEIGHT.SEMIBOLD}>
        {translate('paymentMethods')}

        {core.isMobile && (
          <ButtonCreateMobile
            baseUrl={baseUrl}
            isPermitted={core.permissions['payment-method.create']}
          />
        )}

        {!core.isMobile && (
          <ButtonCreate baseUrl={baseUrl} isPermitted={core.permissions['payment-method.create']} />
        )}
      </Title>
    </div>
  );
};

export default observer(PaymentMethodHeader);
