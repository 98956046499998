import React, { ReactNode } from 'react';

import { ColumnsType } from 'antd/es/table';

import { IPermission } from 'modules/core/models/types';
import Actions from 'modules/directory/components/ActionButtons';
import { translate } from 'modules/localization';
import { IShop } from 'modules/shops/models/types';

export const getColumns = (
  baseUrl: string,
  permissions: IPermission,
  actionDelete: (guid: string) => void,
): ColumnsType<IShop> => {
  return [
    {
      dataIndex: 'short_name',
      title: translate('shopShortName'),
      key: 'short_name',
    },
    {
      dataIndex: 'legal_name',
      title: translate('shopLegalName'),
      key: 'legal_name',
    },
    {
      dataIndex: 'notification_access',
      title: translate('notificationsFromCouriers'),
      key: 'notification_access',
      render: (_, item) => (
        <span>{item?.meta?.notification_access ? translate('yes') : translate('no')}</span>
      ),
    },
    {
      dataIndex: 'disabled',
      render: (disabled): ReactNode => (
        <span>{!disabled ? translate('yes') : translate('no')}</span>
      ),
      title: translate('activity'),
      key: 'isActive',
      width: '10%',
    },
    {
      dataIndex: 'guid',
      key: 'action',
      render: (_, item): ReactNode => (
        <Actions
          item={item}
          baseUrl={baseUrl}
          actionDelete={actionDelete}
          message={{
            deleteCancel: translate('cancel'),
            deleteTitle: translate('shopDeleteConfirm'),
          }}
          permissions={{
            edit: permissions['shops.update'],
            delete: permissions['shops.delete'],
          }}
        />
      ),
      width: '5%',
    },
  ];
};
