import React, { useEffect, useState } from 'react';

import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Form, FormInstance, Input, Select, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { positiveNumberValidator, integerNumberValidator } from 'helpers/form';
import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  form: FormInstance;
  name: number;
  settingCount: number;
  remove: (index: number[] | number) => void;
  isCreate: boolean;
  deliveryMethodGuid: string;
}

const ClusterSettingForm = ({
  form,
  name,
  settingCount,
  remove,
  isCreate,
  deliveryMethodGuid,
}: IProps) => {
  const [deliveryMethod, setDeliveryMethod] = useState<React.ReactNode>(null);
  const [disabledDeliveryMethodGuids, setDisabledDeliveryMethodGuids] = useState<string[]>();

  const deliverySettingsListChangeTrigger = Form.useWatch('deliverySettingsList', form);

  const { deliveryMethods } = useStore();

  useEffect(() => {
    const guids = form
      .getFieldsValue(['deliverySettingsList'])
      ?.deliverySettingsList?.map((item) => {
        if (item && item.delivery_method_guid) {
          return item.delivery_method_guid;
        }
        return null;
      });
    setDisabledDeliveryMethodGuids(guids);

    const deliveryMethodName = deliveryMethods.list.find(
      (item) => item.guid === deliveryMethodGuid,
    );
    setDeliveryMethod(deliveryMethodName?.name);
  }, [deliverySettingsListChangeTrigger]);

  const onSelectDate = (val) => {
    form.setFieldValue(['deliverySettingsList', name, 'handle_start'], val);
  };

  return (
    <div className="cluster-form-settings-wrapper">
      <div className="cluster-form-settings">
        <h2 className="cluster-form-h2 cluster-form-delivery-method-h2">
          {deliveryMethod || translate('newDeliveryMethod')}
          {settingCount > 1 && isCreate ? (
            <>
              <div className="cluster-vertical-line" />
              <Button type="link" className="cluster-button" onClick={() => remove(name)}>
                <DeleteOutlined /> {translate('delete')}
              </Button>
            </>
          ) : null}
        </h2>
        <Form.Item
          className="cluster-form-item"
          label={translate('deliveryMethod')}
          name={[name, 'delivery_method_guid']}
          rules={[{ required: true, message: translate('requiredValidator') }]}
          required
        >
          <DeliveryMethodsSelect disabledGuids={disabledDeliveryMethodGuids} />
        </Form.Item>
        <Form.Item
          className="cluster-form-item"
          label={translate('startDateAndTime')}
          name={[name, 'handle_start']}
          rules={[{ required: true, message: translate('requiredValidator') }]}
          required
        >
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            showNow={false}
            onChange={onSelectDate}
            showTime={{ defaultValue: dayjs('00:00', 'HH:mm'), format: 'HH:mm' }}
          />
        </Form.Item>
        <Form.Item
          className="cluster-form-item"
          label={translate('courierAverageSpeed')}
          name={[name, 'courier_avg_speed']}
          rules={[
            { required: true, message: translate('requiredValidator') },
            { validator: positiveNumberValidator },
            { validator: integerNumberValidator },
          ]}
          required
        >
          <Input
            placeholder={translate('enterAverageSpeed')}
            type="number"
            min={0}
            addonAfter={translate('averageSpeedUnitKmPerHour')}
          />
        </Form.Item>
        <h3 className="cluster-form-h3">{translate('unloadingTimeCalculation')}</h3>
        <Form.Item
          className="cluster-form-item"
          label={translate('unloadingTime')}
          name={[name, 'order_unloading_delay']}
          rules={[
            { required: true, message: translate('requiredValidator') },
            { validator: positiveNumberValidator },
            { validator: integerNumberValidator },
          ]}
          required
        >
          <Input
            placeholder={translate('enterUnloadingTime')}
            type="number"
            min={0}
            addonAfter={translate('timeUnitMinuteShort')}
          />
        </Form.Item>
        <Form.Item
          className="cluster-form-item"
          label={translate('orderWeightForExtraDelay')}
          name={[name, 'order_weight_for_extra_delay']}
          rules={[
            { required: true, message: translate('requiredValidator') },
            { validator: positiveNumberValidator },
            { validator: integerNumberValidator },
          ]}
          required
        >
          <Input
            placeholder={translate('enterOrderWeightForExtraDelay')}
            type="number"
            min={0}
            addonAfter={translate('weightUnit')}
          />
        </Form.Item>
        <Form.Item
          className="cluster-form-item"
          label={translate('orderUnloadingExtraDelay')}
          name={[name, 'order_unloading_extra_delay']}
          rules={[
            { required: true, message: translate('requiredValidator') },
            { validator: positiveNumberValidator },
            { validator: integerNumberValidator },
          ]}
          required
        >
          <Input
            placeholder={translate('enterOrderUnloadingExtraDelay')}
            type="number"
            min={0}
            addonAfter={translate('timeUnitMinuteShort')}
          />
        </Form.Item>
        <h3 className="cluster-form-h3">{translate('routingStartPlan')}</h3>
        <Form.Item
          className="cluster-form-item"
          label={
            <div>
              {translate('orderActualStrategy')}{' '}
              <Tooltip title={translate('orderActualStrategyMeans')}>
                <QuestionCircleOutlined className="cluster-form-tooltip-icon" />
              </Tooltip>
            </div>
          }
          name={[name, 'order_actual_strategy']}
          rules={[{ required: true, message: translate('requiredValidator') }]}
          required
        >
          <Select placeholder={translate('startingPointPlaceholder')}>
            <Select.Option key="order_slot_start" value="order_slot_start">
              {translate('order_slot_start')}
            </Select.Option>
            <Select.Option key="order_assembly_end" value="order_assembly_end">
              {translate('order_assembly_end')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          className="cluster-form-item"
          label={
            <div>
              {translate('shearTime')}
              {', '}
              {translate('timeUnitMinuteShort')}{' '}
              <Tooltip title={translate('shearTimeMeans')}>
                <QuestionCircleOutlined className="cluster-form-tooltip-icon" />
              </Tooltip>
            </div>
          }
          name={[name, 'order_actual_delay']}
          rules={[
            { required: true, message: translate('requiredValidator') },
            { validator: integerNumberValidator },
          ]}
          required
        >
          <Input
            placeholder={translate('shearTimePlaceholder')}
            type="number"
            addonAfter={translate('timeUnitMinuteShort')}
          />
        </Form.Item>
        <Form.Item
          className="cluster-form-item"
          name={[name, 'active']}
          initialValue
          valuePropName="checked"
        >
          <Checkbox>{translate('activity')}</Checkbox>
        </Form.Item>
      </div>
    </div>
  );
};

export default ClusterSettingForm;
