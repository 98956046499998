import './style.less';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import React, { useEffect, useRef, useState } from 'react';

import { Modal, Button } from 'antd';
import L from 'leaflet';
import { observer } from 'mobx-react-lite';
import { Marker, Popup } from 'react-leaflet';

import { translate } from 'modules/localization';
import { ISearchResult } from 'modules/map/containers/Search';
import { iconPointMarker } from 'modules/map/icons';

import SearchResultForm from './SearchResultForm';

interface IProps {
  result: ISearchResult;
}

const SearchResult = ({ result }: IProps) => {
  const [isResultModalShown, showResultModal] = useState(false);

  const onExpandResult = () => {
    showResultModal(true);
  };

  const handleReset = () => showResultModal(false);

  const markerRef = useRef(null);

  useEffect(() => {
    markerRef && markerRef.current.openPopup();
  }, [markerRef.current, result]);

  return (
    result && (
      <>
        <Marker position={[result.raw.lat, result.raw.long]} icon={iconPointMarker} ref={markerRef}>
          <Popup className="search-result-popup" offset={L.point(0, -7)}>
            <div className="search-result">
              <div className="search-result__address">{result.label}</div>
              <div className="search-result__coords">
                {result.y}, {result.x}
              </div>
              <div className="search-result__info">
                <Button
                  type="text"
                  className="search-result__btn"
                  onClick={onExpandResult}
                  size="small"
                >
                  {translate('infoByAddress')}
                </Button>
              </div>
            </div>
          </Popup>
        </Marker>

        <Modal
          title={translate('infoByAddress')}
          open={isResultModalShown}
          onCancel={handleReset}
          className="search-result-modal"
          footer={false}
        >
          <div className="search-result-modal__content">
            <SearchResultForm result={result} />
          </div>
        </Modal>
      </>
    )
  );
};

export default observer(SearchResult);
