import { useEffect } from 'react';

import L, { LatLng } from 'leaflet';
import { useMap } from 'react-leaflet';

interface IViewPortProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapView?: any;
  whenCreated?: (map: L.Map) => void;
  entityID: number | string;
}

const geoJSONOptions = {
  coordsToLatLng: (coords): LatLng => new LatLng(coords[1], coords[0]),
};

export const getBounds = (mapView) => {
  if (!mapView || mapView.length === 0) {
    return null;
  }

  const mapLayer = L.geoJSON(mapView, geoJSONOptions);
  return mapLayer.getBounds();
};

const ViewPort = ({ mapView, whenCreated, entityID }: IViewPortProps) => {
  const map = useMap();

  useEffect(() => {
    const bounds = getBounds(mapView);
    // const zoom = map.getBoundsZoom(bounds)
    if (map) {
      if (bounds) {
        mapView.type === 'Point'
          ? map.fitBounds(bounds, { duration: 0.3 })
          : map.flyToBounds(bounds, { duration: 0.3 });
      } else {
        map.fitWorld({ padding: [0, 0] });
      }
      whenCreated && whenCreated(map);
      map.invalidateSize();
    }
  }, [mapView.length, entityID, whenCreated]);

  return null;
};

export default ViewPort;
