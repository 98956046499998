import './style.less';

import React, { useEffect, useState } from 'react';

import { Button, Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import Loader from 'modules/common/containers/Loader';
import { translate } from 'modules/localization';
import { IRateByPoint } from 'modules/rates/models/types';
import { useStore } from 'services/store';

interface IProps {
  date: string;
  lat: number;
  long: number;
}

interface IRateVariant {
  warehouse_guid: string;
  warehouse_title: string;
  location_guid: string;
  tariff_guid: string;
  tariff_name: string;
}

interface IRatesForRender {
  delivery_method_guid: string;
  delivery_method_name: string;
  variants: IRateVariant[];
}

const transformDataHelper = (ratesListByPoint: IRateByPoint[]): IRatesForRender[] => {
  const methodMap = new Map();

  return ratesListByPoint.reduce((acc, point) => {
    point.delivery_methods.forEach((method) => {
      if (!method.tariff_guid || method.tariff_guid === '') {
        return;
      }

      if (!methodMap.has(method.delivery_method_guid)) {
        const newMethod = {
          delivery_method_guid: method.delivery_method_guid,
          delivery_method_name: method.delivery_method_name,
          variants: [],
        };
        acc.push(newMethod);
        methodMap.set(method.delivery_method_guid, newMethod);
      }

      methodMap.get(method.delivery_method_guid).variants.push({
        warehouse_guid: point.warehouse_guid,
        warehouse_title: point.warehouse_title,
        location_guid: method.location_guid,
        tariff_guid: method.tariff_guid,
        tariff_name: method.tariff_name,
      });
    });

    return acc;
  }, []);
};

const RatesInfoByPoint = ({ date, lat, long }: IProps) => {
  const { rates } = useStore();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    rates.apiStore.getRatesByPoint(lat, long, date);
  }, [date, lat, long]);

  if (rates.isLoadingRatesList) {
    return <Spin indicator={<Loader show />} spinning />;
  }

  if (rates.ratesListByPoint.length === 0) {
    return (
      <div className="rates-point-info rates-point-info--error">
        <p>{translate('noTariffByAddress')}</p>
      </div>
    );
  }

  const dataForRender = transformDataHelper(rates.ratesListByPoint);

  return (
    <div className="rates-point-info">
      <div className="rates-tabs">
        {dataForRender.map((delivery, index) => {
          const isActive = index === activeTab;
          return (
            <Button
              key={delivery.delivery_method_guid + '_tab'}
              type="default"
              onClick={() => setActiveTab(index)}
              size="small"
              className={`rates-tab ${isActive ? 'rates-tab--active' : ''}`}
            >
              {delivery.delivery_method_name}
            </Button>
          );
        })}
      </div>
      {dataForRender.map((delivery, index) => {
        const isActive = index === activeTab;

        return (
          <div
            key={delivery.delivery_method_guid + '_content'}
            className={`rates-tab__content ${isActive ? 'rates-tab__content--active' : ''}`}
          >
            {delivery.variants.map((variant) => {
              if (!variant.tariff_guid || variant.tariff_guid === '') {
                return (
                  <div key={'no-tariff' + variant.location_guid} className="rates-tab__variant">
                    <p className="rates-tab__warehouse">{variant.warehouse_title}</p>
                    <p className="rates-tab__tariff-name">{variant.tariff_name}</p>
                    <p className="rates-tab__no-tariff">
                      {translate('tariffNotSet')}
                      <a
                        href={`/geo/locations/${variant.location_guid}/rates/`}
                        className="rates-tab__setup-tariff"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translate('setUpTariffs')}
                      </a>
                    </p>
                  </div>
                );
              }

              return (
                <div
                  key={variant.tariff_guid + variant.location_guid}
                  className="rates-tab__variant"
                >
                  <p className="rates-tab__warehouse">{variant.warehouse_title}</p>
                  <p className="rates-tab__tariff-name">{variant.tariff_name}</p>
                  <a
                    href={`/geo/locations/${variant.location_guid}/rates/${variant.tariff_guid}/edit`}
                    className="rates-tab__goto-tariff"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {translate('showTariff')}
                  </a>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default observer(RatesInfoByPoint);
