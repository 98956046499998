import { DirectoryApi, DirectoryApiStore } from 'modules/common/store/DirectoryApiStore';

import PaymentMethodApi from '../api/PaymentMethodApi';
import { IPaymentMethod, IPaymentMethodForm } from '../models/types';

const Api: DirectoryApi<IPaymentMethod, IPaymentMethodForm> = {
  getEntity: PaymentMethodApi.getPaymentMethod,
  getEntityList: PaymentMethodApi.getPaymentMethodList,
  createEntity: PaymentMethodApi.createPaymentMethod,
  editEntity: PaymentMethodApi.editPaymentMethod,
};
export class PaymentMethodStore extends DirectoryApiStore<IPaymentMethod, IPaymentMethodForm> {
  constructor() {
    super(Api, '/directories/payment-method');
  }
}
