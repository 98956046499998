import './style.less';

import React, { ReactNode, useState } from 'react';

import { Button, DatePicker, Form, Modal, ModalProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';

import { dateFormat } from 'helpers/string';
import DeliveryMethodsSelect from 'modules/delivery-methods/components/DeliveryMethodsSelect';
import { translate } from 'modules/localization';
import WarehousesSelect from 'modules/warehouses/components/WarehousesSelect';
import { useStore } from 'services/store';

interface IProps {
  isModalOpened: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const RouteGeneratorCreateModal = ({ isModalOpened, onSubmit, onCancel }: IProps & ModalProps) => {
  const { routeGenerator } = useStore();

  const [loadingModal, setLoadingModal] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    setLoadingModal(true);
    routeGenerator.setupRouteSettings(values);
    onSubmit();
  };

  const handleCancel = () => (): void => {
    form.resetFields();
    onCancel();
  };

  const submitClickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    form.submit();
  };

  const renderFooter = (): ReactNode => {
    return (
      <div>
        <Button htmlType="button" key="cancel" onClick={handleCancel()}>
          {translate('cancel')}
        </Button>
        <Button
          loading={loadingModal}
          htmlType="submit"
          key="save"
          onClick={submitClickHandler}
          type="primary"
        >
          {translate('create')}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={translate('routeCreateTitle')}
      footer={renderFooter()}
      open={isModalOpened}
      onCancel={handleCancel()}
      className="rg-create-modal"
    >
      <Form name="basic" form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label={translate('delivery')}
          name="deliveryMethod"
          rules={[{ required: true, message: translate('requiredValidator') }]}
          data-testid="generator-deliveryMethodsInput"
        >
          <DeliveryMethodsSelect isSelectAllOptionAllowed mode="multiple" />
        </Form.Item>
        <div>
          <Form.Item
            label={translate('routeStartDate')}
            name="deliveryDate"
            rules={[{ required: true, message: translate('enterDate') }]}
          >
            <DatePicker
              placeholder={translate('date')}
              format={dateFormat.string}
              disabledDate={(current: Dayjs): boolean => {
                const newCurrent = current.clone();
                const diff = dayjs.duration(dayjs().endOf('day').diff(newCurrent.endOf('day')));
                return current && diff.asDays() > 0;
              }}
              getPopupContainer={(el) => el}
              data-testid="generator-deliveryDateInput"
            />
          </Form.Item>
        </div>

        <Form.Item
          label={translate('warehouse')}
          name="warehouses"
          rules={[{ required: true, message: translate('requiredValidator') }]}
          data-testid="generator-warehouseInput"
        >
          <WarehousesSelect mode={'multiple'} showSearch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(RouteGeneratorCreateModal);
