import { makeAutoObservable } from 'mobx';

import Api from 'api/Api';
import { RestException } from 'api/RestException';
import { IConfig } from 'helpers/config';
import {
  getProfileGUIDFromToken,
  getTokenLocalStorage,
  removeTokenLocalStorage,
  setRefreshLocalStorage,
  setTokenLocalStorage,
} from 'helpers/token';
import CoreApi from 'modules/core/api/CoreApi';
import { IAccountLoginData, IPermission } from 'modules/core/models/types';
import { IProfile } from 'modules/user/models/types';

export class CoreStore {
  permissions: IPermission = null;
  permissionsIsLoading = true;

  accountData: IProfile = null;
  accountIsLoading = true;

  loginIsLoading = false;

  token: string = null;
  config: IConfig = null;

  isHeaderOpened = false;

  screenWidth: number = window.innerWidth;

  get isMobile(): boolean {
    return this.screenWidth <= 768;
  }

  constructor() {
    makeAutoObservable(this);

    this.getToken();
    this.getConfigs().then(() => {
      if (this.token) {
        this.getProfile(getProfileGUIDFromToken(this.token));
        this.getPermissions();
      }
    });
  }

  getProfile = async (profileGuid: string): Promise<IProfile> => {
    try {
      this.accountIsLoading = true;
      const { data: res } = await CoreApi.getProfile(profileGuid);
      this.accountIsLoading = false;
      this.accountData = res.data;
      return res.data;
    } catch (e) {
      this.accountIsLoading = false;
      throw new RestException(e);
    }
  };

  getPermissions = async (): Promise<IPermission> => {
    try {
      this.permissionsIsLoading = true;
      const { data: res } = await CoreApi.getPermissions();
      this.permissions = res.data;
      this.permissionsIsLoading = false;
      return res.data;
    } catch (e) {
      this.permissionsIsLoading = false;
      throw new RestException(e);
    }
  };

  login = async (data: IAccountLoginData): Promise<void> => {
    try {
      this.loginIsLoading = true;
      const { data: res } = await CoreApi.login(data);
      if (res.data && res.data.tokens) {
        this.setToken(res.data.tokens.token);
        this.setRefreshToken(res.data.tokens.refresh);
        await this.getProfile(getProfileGUIDFromToken(this.token));
        await this.getPermissions();
      }
      this.loginIsLoading = false;
    } catch (e) {
      this.loginIsLoading = false;
      throw new RestException(e);
    }
  };

  getConfigs = async (): Promise<void> => {
    try {
      this.config = await fetch('/api-config.json').then((res: Response) => res.json());
      Api.host = this.config.hostMD2;
      Api.serviceUrl = this.config.route.serviceUrl;
    } catch (e) {
      // Empty
      // Может быть сделать обработку, когда не можем получить api-config.json
    }
  };

  setToken = (token: string): void => {
    setTokenLocalStorage(token);
    this.token = token;
  };

  setRefreshToken = (refreshToken: string): void => {
    setRefreshLocalStorage(refreshToken);
  };

  getToken = (): void => {
    const token = getTokenLocalStorage();
    if (token) {
      this.token = token;
    }
  };

  toggleHeader = (newValue: boolean): void => {
    this.isHeaderOpened = newValue;
  };

  setScreenWidth = (width: number): void => {
    this.screenWidth = width;
  };

  logout = (): void => {
    removeTokenLocalStorage();
    window.location.href = '/login';
  };
}
