import { makeAutoObservable } from 'mobx';
import querystring from 'query-string';

import {
  IRates,
  IRatesFilter,
  IRatesForm,
  IRatesMasterData,
  IRateByPoint,
} from 'modules/rates/models/types';
import { routerStore } from 'services/store';

import { RatesApiStore } from './RatesApiStore';
import { initialFilter, initialRateDataForm } from '../models/initial';

export class RatesStore {
  apiStore: RatesApiStore;

  ratesList: IRates[] = [];
  activeRequestRatesList: AbortController | null = null;
  rateDataForm: IRatesForm = initialRateDataForm;
  loadingForm = false;
  filter: IRatesFilter = initialFilter;
  masterData: Partial<IRatesMasterData> = {};
  hoveredGuid: string = null;
  ratesListByPoint: IRateByPoint[] = [];

  get isLoadingRatesList() {
    return this.activeRequestRatesList !== null;
  }

  constructor() {
    this.apiStore = new RatesApiStore(this);
    makeAutoObservable(this);
  }

  setFormData = (item: IRatesForm): void => {
    this.rateDataForm = { ...this.rateDataForm, ...item };
  };

  resetFormData = (): void => {
    this.rateDataForm = initialRateDataForm;
  };

  setFilter = (key: string, value: string[] | boolean | number | string): void => {
    this.filter[key] = value;
  };

  resetFilter = (): void => {
    this.filter = initialFilter;
  };

  setMasterData = (data: Partial<IRatesMasterData>): void => {
    this.masterData = {
      ...this.masterData,
      ...data,
    };
  };

  resetMasterData = (): void => {
    this.masterData = {};
  };

  setHoveredGuid = (guid: string): void => {
    this.hoveredGuid = guid;
  };

  setFilterToUrl = (filter: IRatesFilter = this.filter): void => {
    const qs = querystring.stringify(
      {
        page: filter.current,
        page_size: filter.pageSize,
        service_zone_guid_list: filter.serviceZoneGuidList,
        delivery_method_guid_list: filter.deliveryMethodGuidList,
        active_only: filter.activeOnly,
      },
      { skipNull: true },
    );
    routerStore.history.location.pathname !== '/error' &&
      routerStore.replace(`${routerStore.location.pathname}?${qs}`);
  };
}
