import './style.less';

import React from 'react';

import { observer } from 'mobx-react-lite';

import Form from 'modules/directory/containers/Form';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import { getDataToSet, getFields, prepareData } from './helpers';

interface IProps {
  baseUrl: string;
}

const PaymentMethodForm = ({ baseUrl }: IProps) => {
  const { paymentMethod, core } = useStore();

  const isView = !core.permissions['payment-method.update'];
  const getFormFields = getFields(isView);

  return (
    <Form
      baseUrl={baseUrl}
      actionCreate={paymentMethod.create}
      actionEdit={paymentMethod.edit}
      actionGet={paymentMethod.get}
      getDataToSet={getDataToSet}
      getFields={getFormFields}
      prepareData={prepareData}
      message={{
        editSuccess: translate('paymentMethodEditSuccess'),
        createSuccess: translate('paymentMethodCreateSuccess'),
        editTitle: translate('paymentMethodEditTitle'),
        createTitle: translate('paymentMethodCreateTitle'),
      }}
      isPermittedSubmit={
        core.permissions['payment-method.create'] && core.permissions['payment-method.update']
      }
      isLoading={paymentMethod.isLoadingForm}
    />
  );
};

export default observer(PaymentMethodForm);
