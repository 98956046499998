import { Checkbox, Input } from 'antd';

import { IFieldData } from 'helpers/form';
import { translate } from 'modules/localization';
import { IPaymentMethod, IPaymentMethodForm } from 'modules/payment-method/models/types';

export const getFields =
  (isView: boolean) =>
  (_, isCreate: boolean): IFieldData => ({
    code: {
      component: Input,
      formItem: {
        label: translate('paymentMethodCode'),
        className: 'payment-mathod-form__item',
      },
      name: 'code',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterTitle'),
        disabled: isView,
      },
    },
    web_name: {
      component: Input,
      formItem: {
        label: translate('paymentMethodWebName'),
        className: 'payment-mathod-form__item',
      },
      name: 'web_name',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterTitle'),
        disabled: isView,
      },
    },
    mob_name: {
      component: Input,
      formItem: {
        label: translate('paymentMethodMobName'),
        className: 'payment-mathod-form__item',
      },
      name: 'mob_name',
      params: {
        rules: [
          {
            message: translate('requiredValidator'),
            required: true,
          },
        ],
      },
      props: {
        placeholder: translate('enterTitle'),
        disabled: isView,
      },
    },
    no_payment_permission: {
      component: Checkbox,
      formItem: {
        className: 'payment-mathod-form__item',
      },
      name: 'no_payment_permission',
      params: {
        initialValue: isCreate,
        valuePropName: 'checked',
      },
      props: {
        children: translate('paymentMethodNoPermissions'),
        disabled: isView,
      },
    },
  });

export const getDataToSet = (item: IPaymentMethod): IPaymentMethodForm | Record<string, never> => {
  return {
    code: item.code,
    mob_name: item.mob_name,
    web_name: item.web_name,
    no_payment_permission: item.no_payment_permission,
  };
};

export const prepareData = (formData: IPaymentMethodForm): IPaymentMethodForm => {
  return {
    mob_name: formData.mob_name ? formData.mob_name.trim() : null,
    web_name: formData.web_name ? formData.web_name.trim() : null,
    code: formData.code ? formData.code.trim() : null,
    no_payment_permission: formData.no_payment_permission,
  };
};
