import './style.less';

import { useEffect } from 'react';

import { GeoSearchControl } from 'leaflet-geosearch';
import { observer } from 'mobx-react-lite';
import { useMap } from 'react-leaflet';

import { IConfigGeocoderBMP } from 'helpers/config';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

import SearchProviderBMP from '../SearchProviderBMP';

export interface ISearchResult {
  label: string;
  y: string;
  x: string;
  raw: {
    lat: number;
    long: number;
  };
}

interface IProps {
  geocoderConfig: IConfigGeocoderBMP;
  onResult: (result: ISearchResult) => void;
}

const Search = ({ onResult }: IProps) => {
  const { core } = useStore();
  const map = useMap();
  const provider = new SearchProviderBMP({
    token: core.config.geocoderBMP.token,
    url: core.config.geocoderBMP.url,
  });
  const searchControl = GeoSearchControl({
    provider: provider,
    animateZoom: true,
    autoClose: true,
    autoCompleteDelay: 100,
    keepResult: true,
    style: 'bar',
    showPopup: false,
    popupFormat: ({ result }): string => {
      onResult(result);
      return '';
    },
    retainZoomLevel: false,
    searchLabel: translate('enterSearchQueryAddress'),
    showMarker: false,
  });

  useEffect(() => {
    map && map.addControl(searchControl);

    return () => {
      map.removeControl(searchControl);
    };
  }, []);

  return null;
};

export default observer(Search);
