import Api from 'api/Api';
import { IPagination } from 'api/types';
import {
  IIdentityCreateResponse,
  IIdentityDeleteResponse,
  IIdentityEditResponse,
  IIdentityForm,
  IIdentityGetResponse,
  IProfileCreateResponse,
  IProfileDeactivateResponse,
  IProfileDeleteResponse,
  IProfileEditResponse,
  IProfileForm,
  IProfileGetResponse,
  IProfileListGetResponse,
  IRolesGetResponse,
} from 'modules/user/models/types';

export default class UserApi {
  static searchProfile(
    q: string,
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IProfileListGetResponse> {
    const query = {
      q,
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/search/profiles', query, signal);
  }

  static getProfiles(
    pagination: IPagination,
    signal?: AbortSignal,
  ): Promise<IProfileListGetResponse> {
    const query = {
      page: pagination.current,
      page_size: pagination.pageSize,
    };
    return Api.get('/api/v1/profiles', query, signal);
  }

  static getProfile(guid: string): Promise<IProfileGetResponse> {
    return Api.get(`/api/v1/profiles/${guid}`);
  }

  static createProfile(data: IProfileForm): Promise<IProfileCreateResponse> {
    return Api.post(data, '/api/v1/profiles');
  }

  static editProfile(guid: string, data: IProfileForm): Promise<IProfileEditResponse> {
    return Api.patch(data, `/api/v1/profiles/${guid}`);
  }

  static deleteProfile(guid: string): Promise<IProfileDeleteResponse> {
    return Api.delete(`/api/v1/profiles/${guid}`);
  }

  static getIdentity(profileGuid: string, identityGuid: string): Promise<IIdentityGetResponse> {
    return Api.get(`/api/v1/profiles/${profileGuid}/identities/${identityGuid}`);
  }

  static createIdentity(
    profileGuid: string,
    data: IIdentityForm,
  ): Promise<IIdentityCreateResponse> {
    return Api.post(data, `/api/v1/profiles/${profileGuid}/identities`);
  }

  static editIdentity(
    profileGuid: string,
    identityGuid: string,
    data: IIdentityForm,
  ): Promise<IIdentityEditResponse> {
    return Api.patch(data, `/api/v1/profiles/${profileGuid}/identities/${identityGuid}`);
  }

  static deleteIdentity(
    profileGuid: string,
    identityGuid: string,
  ): Promise<IIdentityDeleteResponse> {
    return Api.delete(`/api/v1/profiles/${profileGuid}/identities/${identityGuid}`);
  }

  static getRoles(): Promise<IRolesGetResponse> {
    return Api.get('/api/v1/dictionaries/roles');
  }

  static deactivateProfile(profileGuid: string, date: string): Promise<IProfileDeactivateResponse> {
    const data = {
      profile_guid: profileGuid,
      deactivation_date: date,
    };
    return Api.post(data, '/api/v1/profiles/schedule_disable ');
  }
}
